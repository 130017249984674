<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          ຈຳນວນຕຸກນ້ຳ
          <span class="primary-color">({{ countFactoryBottle }})</span>
        </h2>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="Search"
          type="text"
          v-model="searchBottleFactory"
          @keyup.enter="fetchBottleFactory()"
        >
        </v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>

    <v-data-table
      :headers="headersHaveWaterBottle"
      :items="bottleFactory"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
      :search="searchBottleFactory"
    >
      <template v-slot:item.round="{ item }">
        <v-chip :color="getRoundColor(item.round)">{{ item.round }}</v-chip>
<!--        <v-chip v-if="item.status === 'using'" class="success">{{item.status}}</v-chip>-->
<!--     <v-chip v-else-if="item.status === 'empty'" class="danger">{{item.status}}</v-chip>-->
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("MM:HH DD-MM-YYYY") }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.updated_at).format("MM:HH DD-MM-YYYY") }}
      </template>
<!--      <template v-slot:item.status="{ item }">-->
<!--        <v-chip :color="getColor(item.status)">{{-->
<!--          getStatus(item.status)-->
<!--        }}</v-chip>-->
<!--        &lt;!&ndash; <v-chip v-if="item.status === 'using'" class="success">{{item.status}}</v-chip>-->
<!--     <v-chip v-else-if="item.status === 'empty'" class="danger">{{item.status}}</v-chip> &ndash;&gt;-->
<!--      </template>-->
<!--      <template v-slot:item.status_type="{ item }">-->
<!--        <v-chip :color="getStatusTypeColor(item.status_type)">-->
<!--          {{ getStatusType(item.status_type) }}-->
<!--        </v-chip>-->
<!--      </template>-->
<!--      <template v-slot:item.actions="{ item }">-->
<!--        <v-icon small class="mr-2" @click="OpenModalEdit(item)">-->
<!--          mdi-pencil-->
<!--        </v-icon>-->
<!--        <v-icon small @click="viewProduct(item.code_id)"-->
<!--          >mdi-map-marker-circle</v-icon-->
<!--        >-->
<!--      </template>-->
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchBottleFactory()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      TableLoading: false,
      exportLoading:false,
      bottleFactory: [],
      countFactoryBottle: 0,
      searchBottleFactory: "",
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 20,
      headersHaveWaterBottle: [
        {
          text: "Barcode",
          align: "start",
          value: "code_id",
          sortable: false,
        },
        {
          text: "ຕຸກນ້ຳ",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "ຈຳນວນຄັ້ງໃນການໃຊ້",
          align: "center",
          value: "round",
        },
        // {
        //   text: "ສະຖານະຕຸກ",
        //   align: "center",
        //   value: "status",
        //   sortable: false,
        // },
        // {
        //   text: "ສະຖານະການເຄື່ອນໄຫວ",
        //   align: "start",
        //   value: "status_type",
        //   sortable: false,
        // },
        {
          text: "Created",
          align: "start",
          value: "created_at",
        },
        {
          text: "Updated",
          align: "start",
          value: "updated_at",
        },
        // { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳມີນ້ຳ`,
    }
  },
  methods: {
    //Bottle Have water
    fetchBottleFactory() {
      this.TableLoading = true;
      this.$axios
        .get("factory/list-products/using", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.searchBottleFactory,
          },
        })
        .then((res) => {
          this.bottleFactory = res.data.products.data;
          this.countFactoryBottle = res.data.products.total;
          this.pagination = res.data.products;
          (this.bottleFactory)
          this.TableLoading = false;
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    getRoundColor(round) {
      if (round >= 20) return "error";
      else return "success";
    },
    getStatus(status) {
      if (status == "using") return "ມີນ້ຳ";
      else if (status == "empty") return "ບໍ່ມີນ້ຳ";
      else if (status == "expire") return "ປິດໃຊ້ງານ";
      else return;
    },
    closeModal() {
      this.$store.commit("modalView_State", false);
    },

    exportData() {
      this.exportLoading = true;
      this.$axios
          .get(
              "export-products/using", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Bottle_In_Factory" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  created() {
    this.fetchBottleFactory();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
