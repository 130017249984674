<template>
  <div>
    <v-row class="my-2">
      <v-col>
        <h3>
          ລວມສາຂາ
          <span class="primary-color">({{ totalBranch }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ລວມຕຸກມີນ້ຳ
          <span class="primary-color">({{ sumBottleBranch }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ລວມຕຸກເປົ່າ
          <span class="primary-color">({{ sumBottleEmptyBranch }})</span>
        </h3>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-data-table
        :headers="headers"
        :items="branches"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <!--Action -->
      <!-- <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("HH:MM DD-MM-YYYY") }}
      </template>-->
      <template v-slot:item.amount="{ item }">
        <v-chip color="success"
        >
          <v-icon small @click="viewBranchProduct(item.id)"
          >{{ item.products.length }}
          </v-icon>
        </v-chip
        >
      </template>
      <template v-slot:item.bottleEmpty="{ item }">
        <div> {{item.emptyBottles !== null ? item.emptyBottles : 0
          }}</div>
      </template>
    </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchDataBranch()"
      ></Pagination>
    </template>

    <!-- Bottle Have Water Branch -->
    <div>
      <v-dialog
          v-model="viewBranch"
          persistent
          width="800px"
          max-with="1200px"
          loading
          v-if="sumBottleInBranch > 0"
      >
        <template @close="close">
          <v-card :loading="loading" :disabled="loading">
            <v-card-title>
              <h4>ຕຸກນ້ຳຢູ່ສາຂາ ({{ sumBottleInBranch }})</h4>
            </v-card-title>
            <v-card-text>
              <v-card-text>
                <v-simple-table
                    v-for="(items, index) in listProductDetail"
                    :key="index"
                >
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">ບາໂຄດ</th>
                      <th class="text-left">ວັນທີ່ຮັບເຂົ້າ</th>
                      <th class="text-left">ວັນທີ່ສົ່ງອອກ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="listProduct in items.products" :key="listProduct.id">
                      <td>{{ listProduct.barcode }}</td>
                      <td>{{ listProduct.import_date }}</td>
                      <td>{{ listProduct.export_date }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1 depressed"
                    text
                    @click="viewBranch = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>

    <!-- Bottle Empty in Branch -->
    <div>
      <v-dialog
          v-model="viewBranchEmpty"
          persistent
          width="800px"
          max-with="1200px"
          loading
          v-if="sumEmptyInBranch > 0"
      >
        <template @close="close">
          <v-card :loading="loading" :disabled="loading">
            <v-card-title>
              <h4>ຕຸກເປົ່າຢູ່ສາຂາ ({{ sumEmptyInBranch }})</h4>
            </v-card-title>
            <v-card-text>
              <v-card-text>
                <v-simple-table
                    v-for="(items, index) in listEmptyProductDetail"
                    :key="index"
                >
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Barcode</th>
                      <th class="text-left">Crated date</th>
                      <th class="text-left">Update date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="listEmpty in items.emptyBottles" :key="listEmpty.id">
                      <td>{{ listEmpty.barcode }}</td>
                      <td>{{ moment(listEmpty.created_at).format("DD-MM-YYYY HH:MM ") }}</td>
                      <td>{{ moment(listEmpty.updated_at).format("DD-MM-YYYY HH:MM") }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1 depressed"
                    text
                    @click="viewBranchEmpty = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import manage_water_bottle from "@/mixins/water/manage_water_bottle";
import Pagination from "@/plugins/pagination/pagination";

export default {
  // mixins: [manage_water_bottle],
  components: {
    Pagination,
  },
  data() {
    return {
      type: "factory",
      exportLoading: false,
      headers: [
        {
          text: "ສາຂາ",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "ເບີໂທ",
          align: "start",
          value: "phone",
          sortable: false,
        },
        {
          text: "ທີ່ຕັ້ງ",
          align: "start",
          value: "ຈຸດສັງເກດ",
          sortable: false,
        },
        // {
        //   text: "Updated",
        //   align: "start",
        //   value: "created_at",
        // },
        {text: "ຕຸກມີນ້ຳ", align: "center", value: "amount", sortable: false},
        {
          text: "ຕຸກເປົ່າ",
          value: "bottleEmpty",
          align: "center",
          sortable: false,
        },
      ],
      loading: false,
      TableLoading: false,
      modalView: false,
      viewBranch: false,
      viewBranchEmpty: false,
      listProductDetail: [],
      listEmptyProductDetail:[],
      branches: [],
      sumBottleInBranch: 0,
      sumEmptyInBranch: 0,
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 20,
      totalBranch: 0,
      sumBottleBranch: 0,
      sumBottleEmptyBranch: 0,
    };
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳສາຂາ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນຕຸກນ້ຳສາຂາ'},
      ]
    }
  },
  methods: {
    viewBranchProduct(id) {
      this.viewBranch = true;
      this.loading = true;
      this.$admin
          .get("/report/product/branch/" + id)
          .then((res) => {
            this.listProductDetail = res.data.listProducts.data;
            this.sumBottleInBranch = res.data.sumTotalBottle;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.viewBranch = true;
          });
    },
    viewEmptyBottleBranch(id) {
      this.viewBranchEmpty = true;
      this.loading = true;
      this.$admin
          .get("/report/product/branch/" + id)
          .then((res) => {
            this.listEmptyProductDetail = res.data.listProducts.data;
            this.sumEmptyInBranch = res.data.sumTotalEmptyBottle;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.viewBranchEmpty = true;
          });
    },
    fetchDataBranch() {
      this.TableLoading = true;
      this.$admin
          .get("/report/product/branch/all", {
            params: {
              page: this.pagination.current_page,
              per_page: this.per_page,
            },
          })
          .then((res) => {
            this.branches = res.data.listProducts.data;
            this.pagination = res.data.listProducts;
            this.totalBranch = res.data.listProducts.total;
            this.sumBottleBranch = res.data.sumTotalBottle;
            this.sumBottleEmptyBranch = res.data.sumTotalEmptyBottle;
            this.TableLoading = false;
          })
          .catch(() => {
            this.TableLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    getColor(status) {
      if (status == "using") return "success";
      else if (status == "empty") return "error";
      else return "green";
    },
    getStatus(status) {
      if (status == "using") return "ມີນ້ຳ";
      else if (status == "empty") return "ບໍ່ມີນ້ຳ";
      else return;
    },
    getStatusType(status_type, status) {
      if (status_type == "inbound_factory" && status == "inbound")
        return "ຢູ່ໂຮງງານດຫັຫດຫັ";
      else if (status_type == "outbound_factory") return "ອອກຈາກໂຮງງານ";
      else if (status_type == "inbound_branch") return "ຢູ່ຂາຂາ";
      else if (status_type == "outbound_branch") return "ອອກຈາກສາຂາ";
      else if (status_type == "customer") return "ຢູ່ນຳລູກຄ້າ";
      else if (status_type == "coming_to_factory") return "ກຳລັງກັບສູ່ໂຮງງານ";
      else return status_type;
    },
    getStatusTypeColor(status) {
      if (status == "inbound_factory") return "warning";
      else if (status == "outbound_factory") return "success";
      else if (status == "inbound_branch") return "info";
      else if (status == "outbound_branch") return "success";
      else if (status == "customer") return "primary";
      else if (status == "coming_to_factory") return "error";
      else return;
    },
    getTrackStatusType(status) {
      if (status == "inbound") return "ເຂົ້າ";
      else if (status == "outbound") return "ອອກຈາກ";
      else return;
    },
    getTrackStoreType(status) {
      if (status == "factory") return "ໂຮງງານ";
      else if (status == "truck") return "ລົດຂົນສົ່ງ";
      else if (status == "branch") return "ສາຂາ";
      else if (status == "customer") return "ລູກຄ້າ";
      else return;
    },

    exportData() {
      this.exportLoading = true;
      this.$admin
          .get(
              "export/product/branch", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "ຕຸກນ້ຳຢູ່ສາຂາ" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  created() {
    this.fetchDataBranch();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
