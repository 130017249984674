<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          ຈຳນວນຕຸກນ້ຳ
          <span class="primary-color">({{ countBottleExpire }})</span>
        </h2>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="Search"
          type="text"
          v-model="searchExpire"
          @keyup.enter="fetchDataBottleExpire()"
        >
        </v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>

    <v-data-table
      :headers="headersBottleExpire"
      :items="expireBottle"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
      :search="searchExpire"
    >
      <template v-slot:item.round="{ item }">
        <v-chip :color="getRoundColor(item.round)">{{ item.round }}</v-chip>
        <!-- <v-chip v-if="item.status === 'using'" class="success">{{item.status}}</v-chip>
     <v-chip v-else-if="item.status === 'empty'" class="danger">{{item.status}}</v-chip> -->
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.updated_at).format("MM:HH DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)">{{
          getStatus(item.status)
        }}</v-chip>
        <!-- <v-chip v-if="item.status === 'using'" class="success">{{item.status}}</v-chip>
     <v-chip v-else-if="item.status === 'empty'" class="danger">{{item.status}}</v-chip> -->
      </template>
      <template v-slot:item.status_type="{ item }">
        <v-chip :color="getStatusTypeColor(item.status_type)">
          {{ getStatusType(item.status_type) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="viewProduct(item.code_id)"
          >mdi-map-marker-circle</v-icon
        >
      </template>
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchDataBottleExpire()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      exportLoading:false,
      expireBottle: [],
      countBottleExpire: 0,
      searchExpire: "",
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 20,
      headersBottleExpire: [
        {
          text: "ລະຫັດຕຸກນ້ຳ",
          align: "start",
          value: "code_id",
          sortable: false,
        },
        {
          text: "ຕຸກນ້ຳ",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "ຈຳນວນຄັ້ງໃນການໃຊ້",
          align: "center",
          value: "round",
        },
        {
          text: "ສະຖານະການເຄື່ອນໄຫວ",
          align: "center",
          value: "status_type",
          sortable: false,
        },
        {
          text: "Updated",
          align: "start",
          value: "updated_at",
        },
        // { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳປິດໃຊ້ງານ`,
    }
  },
  methods: {
    //Bottle No have Water
    fetchDataBottleExpire() {
      this.TableLoading = true;
      this.$axios
        .get("factory/list-products/expire", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.searchExpire,
          },
        })
        .then((res) => {
          this.expireBottle = res.data.products.data;
          this.countBottleExpire = res.data.products.total;
          this.pagination = res.data.products;
          this.TableLoading = false;
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    getRoundColor(round) {
      if (round >= 20) return "error";
      else return "success";
    },
    getColor(status) {
      if (status == "using") return "success";
      else if (status == "empty") return "warning";
      else if (status == "expire") return "error";
      else return "green";
    },
    getStatus(status) {
      if (status == "using") return "ມີນ້ຳ";
      else if (status == "empty") return "ບໍ່ມີນ້ຳ";
      else if (status == "expire") return "ປິດໃຊ້ງານ";
      else return;
    },
    getStatusType(status_type) {
      if (status_type == "inbound_factory") return "ຢູ່ໂຮງງານ";
      else if (status_type == "outbound_factory") return "ອອກຈາກໂຮງງານ";
      else if (status_type == "inbound_branch") return "ຢູ່ຂາຂາ";
      else if (status_type == "outbound_branch") return "ອອກຈາກສາຂາ";
      else if (status_type == "customer") return "ຢູ່ນຳລູກຄ້າ";
      else if (status_type == "coming_to_factory") return "ກຳລັງກັບສູ່ໂຮງງານ";
      else return status_type;
    },
    getStatusTypeColor(status) {
      if (status == "inbound_factory") return "warning";
      else if (status == "outbound_factory") return "success";
      else if (status == "inbound_branch") return "info";
      else if (status == "outbound_branch") return "success";
      else if (status == "customer") return "primary";
      else if (status == "coming_to_factory") return "error";
      else return;
    },
    exportData() {
      this.exportLoading = true;
      this.$axios
          .get(
              "export-products/expire", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Bottle_Close" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  watch: {
    searchExpire: function (value) {
      if (value == "") {
        this.fetchDataBottleExpire();
      }
    },
  },
  created() {
    this.fetchDataBottleExpire();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
