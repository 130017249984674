<template>
  <div>
    <v-row class="my-2">
      <v-col>
        <h2>
          ຈຳນວນລູກຄ້າທີ່ຊື້ນ້ຳ<span class="primary-color">
            ({{ countCustomer }})</span
          >
        </h2>
      </v-col>
      <!-- <v-col>
        <v-select
          :items="listDistricts"
          v-model="selectedDistrict"
          label="ເມືອງ"
          item-text="name"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="selectedVillage"
          :items="listVillages"
          item-text="name"
          item-value="id"
          label="ບ້ານ"
          dense
          outlined
          :rules="ruleVillage"
        ></v-select>
      </v-col> -->
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="ຄົ້ນຫາດ້ວຍ ຊື່, ລະຫັດລູກຄ້າ"
          v-model="search"
          type="text"
          @keyup.enter="Search()"
        >
        </v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-data-table
      :headers="headers"
      :items="customers"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
      :search="search"
    >
      <!--Action -->
      <template v-slot:item.name="{ item }">
        <div>{{ item.name }} {{ item.surname }}</div>
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.address="{ item }">
        <div>{{ item.villageName }}, {{ item.districtName }}</div>
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.amount="{ item }">
        <v-chip color="success">{{ item.products.length }}</v-chip>
      </template>
      <template v-slot:item.phone="{ item }">
        <div>{{ item.phone }}</div>
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.updated_at).format("HH:MM DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-4" @click="viewProduct(item.id)">mdi-eye</v-icon>
        <v-btn
            small
            color="primary"
            @click="exportCustomerBottle(item.id)"
        >Export
        </v-btn
        >
      </template>
    </v-data-table>
    <br />
    <Pagination
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :offset="offset"
      @paginate="fetchDataCustomer()"
    ></Pagination>

    <div v-for="(items, index) in listProductDetail" :key="index">
      <v-dialog
        v-model="viewCustomer"
        persistent
        width="800px"
        max-with="1200px"
        loading
      >
        <template @close="close">
          <v-card :loading="loading" :disabled="loading">
            <v-card-title>
              <h4>ຕຸກນ້ຳຢູ່ນຳລູກຄ້າ</h4>
            </v-card-title>
            <v-card-text>
              <v-card-text>
                <v-simple-table
                  v-for="(items, index) in listProductDetail"
                  :key="index"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">ບາໂຄດ</th>
                        <th class="text-left">ວັນທີນຳເຂົ້າ</th>
                        <th class="text-left">ຜູ້ຮັບຜິດຊອບ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in items.products" :key="item.id">
                        <td>{{ item.barcode }}</td>
                        <td>{{ item.import_date }}</td>

                        <td>{{ item.created_by }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1 depressed"
                  text
                  @click="viewCustomer = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import { GetOldValueOnInputWaterCustomer } from "@/Helpers/GetValue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 20,
      exportLoading:false,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "customer_code",
          sortable: false,
        },
        {
          text: "ຊື່",
          align: "start",
          value: "name",
          sortable: false,
        },
        { text: "ທີ່ຢູ່", align: "start", value: "address", sortable: false },
        {
          text: "ເບີໂທ",
          align: "start",
          value: "phone",
          sortable: false,
        },
        { text: "ຈຳນວນ", value: "amount" },
        {
          text: "Updated",
          align: "start",
          value: "updated_at",
        },
        { text: "Actions", value: "actions", sortable: false ,width:"150px"},
      ],
      ruleVillage: [(v) => !!v || "Village is required"],
      loading: false,
      TableLoading: false,
      viewCustomer: false,
      customers: [],
      countCustomer: 0,
      listProductDetail: [],

      selectedDistrict: "",
      selectedVillage: "",
      listDistricts: [],
      listVillages: [],
      selectedDistrictAdd: "",
      filterVillages: [],
      filterCustomer: [],
      search: "",
      oldVal: "",
    };
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳຢູ່ນຳລູກຄ້າ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນຕຸກນ້ຳຢູ່ນຳລູກຄ້າ'},
      ]
    }
  },
  methods: {
    fetchDataCustomer() {
      this.TableLoading = true;
      (this.search);
      this.$admin
        .get("/report/product/customer/all", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search,
          },
        })
        .then((res) => {
          this.customers = res.data.listProducts.data;
          this.countCustomer = res.data.listProducts.total;
          this.pagination = res.data.listProducts;
          this.TableLoading = false;
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    viewProduct(id) {
      (id);
      this.viewCustomer = true;
      this.loading = true;
      this.$admin
        .get("/report/product/customer/" + id)
        .then((res) => {
          this.listProductDetail = res.data.listProducts.data;
          (this.listProductDetail);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.viewCustomer = false;
        });
    },
    exportData() {
      this.exportLoading = true;
      this.$admin
          .get(
              "export/product/customer", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "ຕຸກນ້ຳຢູ່ນຳລູກຄ້າ" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    exportCustomerBottle($id) {
      this.$admin
          .get(
              "export/product/customer/" + $id, {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "ຕຸກນ້ຳຢູ່ນຳລູກຄ້າ" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
            }, 100);
          })
          .catch(() => {
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    Search() {
      GetOldValueOnInputWaterCustomer(this);
    },
  },
  watch: {
    selectedDistrict: function (dist_id) {
      this.FilterVillages(dist_id);
      //  this.searchCustomers(village_id);
    },
    search: function (value) {
      if (value == "") {
        this.fetchDataCustomer();
      }
    },
  },
  created() {
    this.fetchDataCustomer();
  },
};
</script>

<style>
.primary-color {
  color: #00c1d2;
}
</style>
