export default {
  data() {
    return {
      loading: false,
      TableLoading: false,
      exportLoading:false,
      products: [],
      product: {},
      product_edit: {},

      selectedFactory: "",
      factories:[],
      selectedStatus: "all",
      distributionCenters:[],
      selectedDistributions:[],
      selectedDistribution:1,

      listType: [
      ],
      selectedType:"",
      headers: [
        {
          text: "ບາໂຄດ",
          align: "start",
          value: "barcode",
          sortable: false
        },
        {
          text: "ຕຸກນ້ຳ",
          align: "start",
          value: "name",
          sortable: false
        },
        {
          text: "ຈຳນວນ",
          align: "center",
          value: "count",
        },
        {
          text: "ອັບເດດລ່າສຸດ",
          align: "start",
          value: "updated_at",
        },
      ],
      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "Something when wrong!",
      },
      barcode: "",
      branch: {},
      customer: {},
      countTotal: 0,
      countUsing: 0,
      countEmpty: 0,

      //Pagination
      offset: 15,
      pagination: {},
      per_page: 20,
      search: "",
      error: "",
      usingError: "",
    };
  },
  methods: {
    //All Products
    fetchDistribution() {
      if(this.selectedFactory !== ""){
        this.TableLoading = true;
        this.$admin
            .get(`report/product/warehouse/${this.selectedDistribution}`, {
              params: {
                page: this.pagination.current_page,
                per_page: this.per_page,
                search: this.search,
                type:this.selectedStatus
              },
            })
            .then((res) => {
              this.products = res.data.listBottleTypes;
              this.countTotal = res.data.sumTotalBottle;
              this.countEmpty = res.data.sumTotalEmptyBottle;
              // this.pagination = res.data.products;
              this.TableLoading = false;
            })
            .catch(() => {
              this.TableLoading = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
      }
    },
    getFactory() {
      this.$axios.get('list-factory-and-warehouses').then(res => {
        this.factories = res.data.factories;
        this.distributionCenters = res.data.wareHouses;
        this.selectedFactory = res.data.factories[0].id;
        this.selectedDistributions = this.distributionCenters.filter(warehouse => warehouse.factory_id === this.selectedFactory);
        this.TableLoading = false;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },

    // Tracking
    viewProduct(trackingId) {
      this.barcode = trackingId.code_id;
      // this.$store.commit("modalView_State", true);
      //   this.loading = true;
      this.$axios
        .get("track-order-status/" + trackingId)
        .then((res) => {
          this.$store.commit("modalView_State", true);
          this.tracking = res.data.tracking;
          // this.driver = res.tracking.driver;
          // (this.driver);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    //Close Bottle
    closeModal() {
      this.$store.commit("modalView_State", false);
    },

    exportData() {
      this.exportLoading = true;
      this.$admin
          .get(
              "export/product/warehouse", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Bottle-Warehouse" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  created() {
      this.getFactory();
      this.fetchDistribution();
  },
  watch: {
    "selectedFactory":function (value) {
      if(value){
        this.selectedDistributions = this.distributionCenters.filter(warehouse => warehouse.factory_id === value);
        this.selectedDistribution = this.selectedDistributions[0].id;
        this.fetchDistribution();
      }
    },
  },
};
