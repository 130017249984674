export default {
  data() {
    return {
      loading: false,
      TableLoading: false,
      exportLoading:false,
      products: [],
      product: 0,
      product_edit: {},
      haveWaterBottle: [],
      emptyWaterBottle: [],
      expireBottle: [],

      selectedFactory: "",
      factories:[],
      selectedStatus: "all",
      listStatuses:[
        {
          type: "all",
          name: "ທັງໝົດ"
        },
        {
          type: "using",
          name: "ຕຸກມີນ້ຳ"
        },
        {
          type: "empty",
          name: "ຕູກເປົ່າ"
        },
        {
          type: "expire",
          name: "ຕຸກໝົດອາຍຸ"
        }


      ],

      server_errors: {
        quantity: "",
        type_id: "",
      },
      listType: [
      ],
      selectedType:"",
      headers: [
        {
          text: "ບາໂຄດ",
          align: "start",
          value: "code_id",
          sortable: false
        },
        {
          text: "ຕຸກນ້ຳ",
          align: "start",
          value: "name",
          sortable: false
        },
        // {
        //   text: "ຈຳນວນຄັ້ງໃນການໃຊ້",
        //   align: "center",
        //   value: "round",
        // },
        // { text: "ສະຖານະຕຸກ", align: "center", value: "status",sortable: false },
        // {
        //   text: "ສະຖານະການເຄື່ອນໄຫວ",
        //   align: "center",
        //   value: "status_type",
        //   sortable: false
        // },
        {
          text: "ອັບເດດລ່າສຸດ",
          align: "start",
          value: "updated_at",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      nameRules: [
        (v) => !!v || "Amount is required",
      ],
      ruleType: [(v) => !!v || "Type is required"],
      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "Something when wrong!",
      },
      tracking: [],
      barcode: "",
      branch: {},
      customer: {},
      countTotal: 0,
      countUsing: 0,
      countEmpty: 0,
      countClose: 0,
      countExpire: 0,

      //Pagination
      offset: 15,
      pagination: {},
      per_page: 20,
      driver: {},
      search: "",
      error: "",
      usingError: "",
    };
  },
  methods: {
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },

    SubmitItem() {
      this.loading = true;
      const data = {
        quantity: this.product,
        type_id: this.selectedType,
      }
      this.$axios
        .post("factory/create-product", data)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              // this.$store.commit("modalAdd_State", false);
              this.reset();
              this.product = {};
              this.getData();
              this.CloseModalAdd();
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          //  this.reset();
          if (error.response.status == 422) {
            this.error = error.response.data.msg;
          }
        });
    },

    CloseModalAdd() {
      this.product = {};
      this.reset();
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.product_edit = item;
      this.$store.commit("modalEdit_State", true);
    },

    UpdateItem() {
      this.product_edit.barcode = this.product_edit.code_id;
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$axios
          .put("factory/edit-product/" + this.product_edit.id, this.product_edit)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.CloseModalEdit();
                this.product_edit = {};
                this.reset();
                this.getData();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              this.usingError = error.response.data.msg;
              for (let [key, product] of Object.entries(obj)) {
                this.server_errors[key] = product[0];
              }
            }
          });
      }
    },

    CloseModalEdit() {
      this.loading = false;
      this.reset();
      this.product_edit = {};
      this.$store.commit("modalEdit_State", false);
    },

    //All Products
    getData() {
      if(this.selectedFactory !== ""){
        this.TableLoading = true;
        this.$admin
            .post(`report/product/factory/${this.selectedFactory}`, {
              page: this.pagination.current_page,
              per_page: this.per_page,
              search: this.search,
              type:this.selectedStatus
            })
            .then((res) => {
              this.products = res.data.products.data;
              this.countTotal = res.data.countTotal;
              this.countUsing = res.data.countUsing;
              this.countEmpty = res.data.countEmpty;
              this.countClose = res.data.countClose;
              this.countExpire = res.data.countExpire;
              this.pagination = res.data.products;
              this.TableLoading = false;
            })
            .catch(() => {
              this.TableLoading = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
      }
    },
    getFactory() {
      this.$admin.get('list-factories').then(res => {
        this.factories = res.data.data.data;
        this.selectedFactory = res.data.data.data[0].id;
        this.TableLoading = false;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    getProductType() {
      this.TableLoading = true;
      this.$axios
        .get("list-bottle-types")
        .then((res) => {
          this.listType = res.data.bottle_types;
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem() {
      this.$store.commit("modalDelete_State", true);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.$admin
        .delete("delete-village/" + this.villageID)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.getData();
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    //Get Round color
    getRoundColor(round) {
      if (round >= 20) return "error";
      else return "success";
    },

    getColor(status) {
      if (status == "using") return "success";
      else if (status == "empty") return "warning";
      else if (status == "expire") return "error";
      else return "green";
    },
    getStatus(status) {
      if (status == "using") return "ມີນ້ຳ";
      else if (status == "empty") return "ບໍ່ມີນ້ຳ";
      else if (status == "expire") return "ປິດໃຊ້ງານ";
      else return;
    },
    getStatusType(status_type) {
      if (status_type == "inbound_factory") return "ຢູ່ໂຮງງານ";
      else if (status_type == "outbound_factory") return "ອອກຈາກໂຮງງານ";
      else if (status_type == "inbound_branch") return "ຢູ່ຂາຂາ";
      else if (status_type == "outbound_branch") return "ອອກຈາກສາຂາ";
      else if (status_type == "customer") return "ຢູ່ນຳລູກຄ້າ";
      else if (status_type == "coming_to_factory") return "ກຳລັງກັບສູ່ໂຮງງານ";
      else return status_type;
    },
    getStatusTypeColor(status) {
      if (status == "inbound_factory") return "warning";
      else if (status == "outbound_factory") return "success";
      else if (status == "inbound_branch") return "info";
      else if (status == "outbound_branch") return "success";
      else if (status == "customer") return "primary";
      else if (status == "coming_to_factory") return "error";
      else return;
    },
    // Tracking
    viewProduct(trackingId) {
      this.barcode = trackingId.code_id;
      // this.$store.commit("modalView_State", true);
      //   this.loading = true;
      this.$axios
        .get("track-order-status/" + trackingId)
        .then((res) => {
          this.$store.commit("modalView_State", true);
          this.tracking = res.data.tracking;
          // this.driver = res.tracking.driver;
          // (this.driver);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    //Close Bottle
    closeBottle(id) {
      this.loading = true;
      this.$admin
        .get("bottle/expire/" + id)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              this.getData();
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    closeModal() {
      this.$store.commit("modalView_State", false);
    },
    getTrackStatusType(status) {
      if (status == "inbound") return "ເຂົ້າ";
      else if (status == "outbound") return "ອອກຈາກ";
      else return;
    },
    getTrackStoreType(status) {
      if (status == "factory") return "ໂຮງງານ";
      else if (status == "truck") return "ລົດຂົນສົ່ງ";
      else if (status == "branch") return "ສາຂາ";
      else if (status == "customer") return "ລູກຄ້າ";
      else return;
    },

    exportData() {
      this.exportLoading = true;
      this.$axios
          .get(
              "export-products/all", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Bottle" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  created() {
    this.getFactory();
      // this.getData();
    this.getProductType();
  },
  watch: {
    "product.barcode": function () {
      this.error = "";
    },
    "product_edit.code_id": function () {
      this.server_errors.barcode = "";
      this.usingError = "";
    },
    "selectedFactory":function (value) {
      if(value){
        this.getData();
      }
      // this.getData();
    },
    selectedStatus:function (value) {
      if(value){
        this.getData();
      }
    },
    search: function (value) {
      if (value == "") {
        this.getData();
      }
    },
  },
};
